import request from '../request/index.js'
export function login(data) { //登录接口
  return request({
    url: '/api/sys/login',
    method: 'post',
    data:(data ? data :' ')
  })
}

export function geteqlive(data) { //获取分屏数据
  return request({
    url: '/api/eq/geteqlive',
    method: 'get',
    params:(data ? data :' ')
  })
}
export function getLiveList(data) { //获取摄像头列表
  return request({
    url: '/api/eq/getliveList',
    method: 'get',
    params:data
  })
}

export function setInsert(data) { //绑定摄像头到画面
  return request({
    url: '/api/eq/insert',
    method: 'post',
    data:(data ? data :' ')
  })
}


export function deleteDevice(data) { //绑定摄像头到画面
  return request({
    url: '/api/eq/delete',
    method: 'post',
    data:(data ? data :' ')
  })
}


export function cameraControl(data) { //控制摄像头
  return request({
    url: '/api/eq/sdk/upControl',
    method: 'post',
    data:(data ? data :' ')
  })
}
export function getAlarmMessage(data) { //报警记录
  return request({
    url: '/api/sys/app/getAlrm',
    method: 'post',
    data:(data ? data :' ')
  })
}