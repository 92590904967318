import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  username:"",
  userrole:-1
  },
  mutations: {
    setUserName(state,name){
    
      state.username=name
    },
    setUserRole(state,role){
    
      state.userrole=role
    }
  },
  actions: {
   
  },
  getters:{
    username: state => state.username,
    userrole: state => state.userrole
  },
  modules: {
  }
})
