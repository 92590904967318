<template>
  <div class="box">
    <!-- 头部开始 -->
    <header>
      <div class="left-flex">
        <div class="logoBox">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="date">
          <span class="time">{{ time }}</span>
          <div class="day">
            <span class="today">{{ weekDay }}</span>
            <span class="years">{{ day }}</span>
          </div>
        </div>
      </div>
      <div class="title">
        <p class="title-cn">现场安全生产实时管理平台</p>
        <span class="title-en">
          On-site safety production real time management platform</span
        >
      </div>
      <div class="status">
        <span class="username">{{username}}</span>
        <span class="loginout" @click="loginout">退出</span>
      </div>
    </header>
    <!-- 头部结束 -->
    <!-- 视频部分开始 -->
    <div class="movie-part">
      <!-- 一画面 -->
      <!-- 事件列表实时专用播放模块 -->
      <div class="movie-box" v-if="showWarmLiveVideo && isFullScreen.length==0">
        <div class="movie-header">
          <div class="movie-title">地点 : 地点A</div>
          <div
            class="movie-handle"
            :style="
              activeControl == 99
                ? 'background-image: url(' +
                  require('../assets/control-active.png') +
                  ')'
                : ' background-image: url(' +
                  require('../assets/control.png') +
                  ')'
            "
            @click.stop="controlHeight(99)"
          >
            <div class="bth-box" v-if="isActiveControl(99)" @click.stop="">
              <div class="bth-item"  @click="fullScreen('rtmp','rtmp://192.168.0.109:1935/live/bea82f6e-1593-4aae-8985-42c1d722c492')">全屏</div>
              <!-- <div class="bth-item" @click.stop="changeVideoSteam(1)">切换</div> -->
              <div class="bth-item" @click="backWarmVideo">关闭</div>
            </div>
          </div>
        </div>
        <vue-video
          class="movie1"
          refName="video99"
          ref="video99"
          liveSrc="rtmp://192.168.0.107:1935/live/bea82f6e-1593-4aae-8985-42c1d722c492"
        ></vue-video>
      </div>
      <!-- 事件列表专用播放模块 -->
      <div class="movie-box" v-if="showWarmVideo && isFullScreen.length==0">
        <div class="movie-header">
          <div class="movie-title">地点 : 地点A</div>
          <div
            class="movie-handle"
            :style="
              activeControl == 99
                ? 'background-image: url(' +
                  require('../assets/control-active.png') +
                  ')'
                : ' background-image: url(' +
                  require('../assets/control.png') +
                  ')'
            "
            @click.stop="controlHeight(99)"
          >
            <div class="bth-box" v-if="isActiveControl(99)" @click.stop="">
              <div class="bth-item" @click="fullScreen('video',alarmLiveVideo)">全屏</div>
              <div class="bth-item" @click="alarmIsLive">实时</div>
              <div class="bth-item" @click="showWarmVideo = false">关闭</div>
            </div>
          </div>
        </div>

        <video class="movie1" :src="alarmLiveVideo" controls></video>
      </div>
      <!--  全屏专用组件-->
       <div class="movie-box-full" v-if="isFullScreen.length!=0">
       <img src="../assets/close.png" class="close" alt="" @click="closeFullScreen(true)">
       <vue-video
            :playType="fullMovieDate.type=='video' ? 'video/mp4' : 'rtmp/mp4'"
            class="movie-full"
            refName="videoFull"
            :aspectRatio="'22:12'"
            ref="videoFull"
            :liveSrc="fullMovieDate.url"
            key="full"
          ></vue-video>
      </div>
      <!--  -->
      <div
        class="movie-box"
        v-if="
          frameIs == 1 && showWarmVideo == false && showWarmLiveVideo == false && isFullScreen.length==0
        "
      >
        <!-- 画面有默认视频资源时候的模块 -->
        <div
          v-if="
            isActiveChange(1) == false &&
            (liveList.length > 0 ? liveList[0].eqId > 0 : false)
          "
        >
          <div class="movie-header">
            <div class="movie-title">{{`${liveList[0].pname ? liveList[0].pname + '-': '' } ${liveList[0].cameraSn ? liveList[0].cameraSn : ''}`}}</div>
            <div
              class="movie-handle"
              :style="
                activeControl == 1
                  ? 'background-image: url(' +
                    require('../assets/control-active.png') +
                    ')'
                  : ' background-image: url(' +
                    require('../assets/control.png') +
                    ')'
              "
              @click.stop="controlHeight(1)"
            >
              <div
                class="bth-box"

                v-if="frameIs == 1 && isActiveControl(1)"
                @click.stop=""
              >
                <div class="bth-item" @click="fullScreen(liveList[0].sid)">全屏</div>
                <div class="bth-item" @click.stop="changeVideoSteam(1)">
                  切换
                </div>
                <div class="bth-item" @click="closeVideo(liveList[0].sid)">
                  关闭
                </div>
              </div>
            </div>
          </div>
          <vue-video
            class="movie1"
            refName="video1"
            key="only1"
            ref="video1"
            :imgSrc="liveList[0].url"
            :liveSrc="liveList[0].url"
          ></vue-video>
        </div>
        <!-- 画面有默认视频资源模块结束 -->
        <!-- 画面无默认视频资源模块开始 -->
        <div
          class="add-movie"
          v-if="
            frameIs == 1 &&
            isActiveChange(1) == false &&
            showWarmVideo == false &&
            (liveList.length > 0 ? liveList[0].eqId == null : false)
          "
        >
          <img
            src="../assets/add-movie.png"
            @click="changeVideoSteam(1)"
            alt=""
          />
        </div>
        <!-- 画面无默认视频资源模块结束 -->
        <!-- 添加播放源/切换播放源 开始-->
        <div v-if="frameIs == 1 && isActiveChange(1)">
          <div class="movie-header">
            <div class="movie-title"></div>
            <div
              class="movie-handle"
              :style="
                isActiveControl(1)
                  ? 'background-image: url(' +
                    require('../assets/control-active.png') +
                    ')'
                  : ' background-image: url(' +
                    require('../assets/control.png') +
                    ')'
              "
              @click.stop="controlHeight(1)"
            >
              <div
                class="bth-box"
                style="left:-30px!important;top:40px"
                v-if="frameIs == 1 && isActiveControl(1)"
                @click.stop=""
              >
                <div class="bth-item-disabled">全屏</div>
                <div class="bth-item-disabled">切换</div>
                <div class="bth-item" @click="closeChange(1)">返回</div>
              </div>
            </div>
          </div>
          <div class="camera-box">
            <div class="camera-item">
              <div class="camera-header">
                <span>监控编号</span>
                <span>项目名称</span>
                <span>项目地点</span>
                <span>状态</span>
                <span>操作</span>
              </div>
              <div
                class="camera-body"
                v-for="(item, count) in cameraList"
                :key="item.deviceId"
              >
                <span>{{ item.cameraSn }}</span>
                <span style="font-size:13px">{{item.pname ? item.pname : '—— ——'}}</span>
                  <span style="font-size:13px">{{item.paddress ? item.paddress : '—— ——'}}</span>
                  <span style="font-size:13px" v-if="item.eqStatus === 0">正常</span>
                  <span style="font-size:13px;color:#E6A23C" v-else-if="item.eqStatus === 1">损坏</span>
                  <span style="font-size:13px;color:#909399" v-else-if="item.eqStatus === 2">报废</span>
                <div>
                  <el-switch
                   v-if="userrole==0"
                    v-model="item.liveStatus"
                    :disabled="((item.liveStatus == 1 && item.eqId != liveList[0].eqId) || item.eqStatus!==0 )"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#46BAFF"
                    inactive-color="#4B5963"
                    @change="liveStatusChange($event, 0, item.eqId, 1, count)"
                  >
                  </el-switch>
                  <a  v-else class="astyle" @click.stop="startLive($event, 0, item.eqId, 1, count)">播放</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 添加播放源/切换播放源 结束-->
      </div>

      <!-- 四画面正常播放-->
      <div
        class="movie-four"
        v-if="
          frameIs == 4 &&
          showWarmVideo == false &&
          showWarmLiveVideo == false &&
          isPullingUp.length == 0 &&
          isFullScreen.length==0
        "
      >
        <!-- 画面有默认视频资源时候的模块 -->
        <div v-for="(item, index) in liveList" :key="item.sid + 'four'">
          <div
            class="movie-box-four"
            :style="
              activeChoose === index
                ? 'border: 2px solid #46BAFF;'
                : 'border: 2px solid #13345F;'
            "
            @click="activeChoose = index"
            :key="item.deviceId"
            v-if="isActiveChange(item.sid) == false && item.eqId > 0"
          >
            <div class="movie-header">
              <div class="movie-title">{{`${item.pname ? item.pname + ' -': '' } ${item.cameraSn ? item.cameraSn : ''}`}}</div>
              <div
                class="movie-handle"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-four"
                  v-if="frameIs == 4 && isActiveControl(item.sid)"
                >
                  <div class="bth-item"  @click="fullScreen(item.sid)">全屏</div>
                  <div
                    class="bth-item"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <!-- <div class="bth-item">实时</div> -->
                  <div class="bth-item" @click="closeVideo(item.sid)">关闭</div>
                </div>
              </div>
            </div>
            <vue-video
              class="movie2"
              :key="`vbx4_${index}`"
              @dblclick.native="pullUp(item.sid, index)"
              :refName="'video' + (index + 1)"
              :ref="'video' + (index + 1)"
              :imgSrc="item.url"
              :liveSrc="item.url"
            ></vue-video>
          </div>
          <!--  -->
          <!-- 画面切换播放源 -->
          <div class="movie-box-four" v-if="isActiveChange(item.sid)">
            <div class="movie-header">
              <div class="movie-title"></div>
              <div
                class="movie-handle"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-four"
                  v-if="frameIs == 4 && isActiveControl(item.sid)"
                >
                  <div class="bth-item-disabled">全屏</div>
                  <div
                    class="bth-item-disabled"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <div class="bth-item" @click.stop="closeChange(item.sid)">
                    返回
                  </div>
                </div>
              </div>
            </div>
            <div class="camera-box-four">
              <div class="camera-item-four">
                <div class="camera-header">
                  <span>监控编号</span>
                  <span>项目名称</span>
                  <span>项目地点</span>
                  <span>状态</span>
                  <span>操作</span>
                </div>
                <div
                  class="camera-body"
                  v-for="(items, count) in cameraList"
                  :key="items.deviceId"
                >
                  <span>{{ items.cameraSn }}</span>
                 <span style="font-size:13px">{{items.pname ? items.pname : '—— ——'}}</span>
                  <span style="font-size:13px">{{items.paddress ? items.paddress : '—— ——'}}</span>
                  <span style="font-size:13px" v-if="items.eqStatus === 0">正常</span>
                  <span style="font-size:13px;color:#E6A23C" v-else-if="items.eqStatus === 1">损坏</span>
                  <span style="font-size:13px;color:#909399" v-else-if="items.eqStatus === 2">报废</span>
                  <div>
                    <el-switch
                    v-if="userrole==0"
                      v-model="items.liveStatus"
                      :disabled="((items.liveStatus == 1 && items.eqId != liveList[index].eqId) || items.eqStatus!==0 )"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#46BAFF"
                      inactive-color="#4B5963"
                      @change="
                        liveStatusChange(
                          $event,
                          index,
                          items.eqId,
                          index + 1,
                          count
                        )
                      "
                    >
                    </el-switch>
                    <a v-else  class="astyle" @click.stop="startLive( $event,
                          index,
                          items.eqId,
                          index + 1,
                          count)">播放</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 画面无默认视频资源模块开始 -->
          <div
            class="movie-box-four"
            v-if="isActiveChange(item.sid) === false && item.eqId === null"
          >
            <div class="add-movie-small">
              <img
                src="../assets/add-small.png"
                @click="changeVideoSteam(item.sid)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 画面有默认视频资源模块结束 -->
       <!-- 9画面 -->
        <!-- 四画面正常播放-->
      <div
        class="movie-four"
        v-if="
          frameIs == 9 &&
          showWarmVideo == false &&
          showWarmLiveVideo == false &&
          isPullingUp.length == 0 &&
          isFullScreen.length==0
        "
      >
        <!-- 画面有默认视频资源时候的模块 -->
        <div v-for="(item, index) in liveList" :key="item.sid + 'four'">
          <div
            class="movie-box-nine"
            :style="
              activeChoose === index
                ? 'border: 2px solid #46BAFF;'
                : 'border: 2px solid #13345F;'
            "
            @click="activeChoose = index"
            :key="item.deviceId"
            v-if="isActiveChange(item.sid) == false && item.eqId > 0"
          >
            <div class="movie-header" style="height:40px;line-height:40px">
              <div class="movie-title" style="font-size:20px">{{`${item.pname ? item.pname + ' -': '' } ${item.cameraSn ? item.cameraSn : ''}`}}</div>
              <div
                class="movie-handle-six"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-six-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control-six.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-nine"
                  v-if="frameIs == 9 && isActiveControl(item.sid)"
                >
                  <div class="bth-item" @click="fullScreen(item.sid)" style="padding:2px 3px;margin-top:8px;font-size:16px;line-height:26px">全屏</div>
                  <div
                    class="bth-item"
                    style="padding:2px 3px;margin-top:8px;font-size:16px;line-height:26px"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <!-- <div class="bth-item">实时</div> -->
                  <div class="bth-item" @click="closeVideo(item.sid)" style="padding:2px 3px;margin-top:8px;font-size:16px;line-height:26px">关闭</div>
                </div>
              </div>
            </div>
            <vue-video
              class="movie2"
              :key="`vbx9_${index}`"
              @dblclick.native="pullUp(item.sid, index)"
              :refName="'video' + (index + 1)"
              :ref="'video' + (index + 1)"
              :imgSrc="item.url"
              :liveSrc="item.url"
            ></vue-video>
          </div>
          <!--  -->
          <!-- 画面切换播放源 -->
          <div class="movie-box-nine" v-if="isActiveChange(item.sid)">
            <div class="movie-header">
              <div class="movie-title"></div>
              <div
                class="movie-handle-six"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-six-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control-six.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-nine"
                   style="padding:0 4px;"
                  v-if="frameIs == 9 && isActiveControl(item.sid)"
                >
                  <div class="bth-item-disabled"  style="padding:2px 3px;margin-top:8px;font-size:16px">全屏</div>
                  <div
                    class="bth-item-disabled"
                     style="padding:2px 3px;margin-top:8px;font-size:16px"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <div class="bth-item" style="padding:2px 3px;margin-top:8px;font-size:16px" @click.stop="closeChange(item.sid)">
                     
                    返回
                  </div>
                </div>
              </div>
            </div>
            <div class="camera-box-four" style="width: calc(100% - 10px);height: calc(100% - 80px);">
              <div class="camera-item-four" >
                <div class="camera-header">
                  <span style="font-size:15px">监控编号</span>
                  <span style="font-size:15px">项目名称</span>
                  <span style="font-size:15px">项目地点</span>
                  <span style="font-size:15px">状态</span>
                  <span style="font-size:15px">操作</span>
                </div>
                <div
                  class="camera-body"
                  v-for="(items, count) in cameraList"
                  :key="items.deviceId"
                >
                  <span style="font-size:13px">{{ items.cameraSn }}</span>
                <span style="font-size:13px">{{items.pname ? items.pname : '—— ——'}}</span>
                  <span style="font-size:13px">{{items.paddress ? items.paddress : '—— ——'}}</span>
                  <span style="font-size:13px" v-if="items.eqStatus === 0">正常</span>
                  <span style="font-size:13px;color:#E6A23C" v-else-if="items.eqStatus === 1">损坏</span>
                  <span style="font-size:13px;color:#909399" v-else-if="items.eqStatus === 2">报废</span>
                  <div>
                    <el-switch
                    v-if="userrole==0"
                      v-model="items.liveStatus"
                      :disabled="((items.liveStatus == 1 && items.eqId != liveList[index].eqId) || items.eqStatus!==0 )"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#46BAFF"
                      inactive-color="#4B5963"
                      @change="
                        liveStatusChange(
                          $event,
                          index,
                          items.eqId,
                          index + 1,
                          count
                        )
                      "
                    >
                    </el-switch>
                     <a v-else  class="astyle" @click.stop="startLive( $event,
                          index,
                          items.eqId,
                          index + 1,
                          count)">播放</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 画面无默认视频资源模块开始 -->
          <div
            class="movie-box-nine"
            v-if="isActiveChange(item.sid) == false && item.eqId == null"
          >
            <div class="add-movie-small">
              <img
              style="width:71px;height:71px"
                src="../assets/add-small.png"
                @click="changeVideoSteam(item.sid)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
       <!--  -->

       <!-- 16画面 -->
        <!-- 四画面正常播放-->
      <div
        class="movie-four"
        v-if="
          frameIs == 16 &&
          showWarmVideo == false &&
          showWarmLiveVideo == false &&
          isPullingUp.length == 0 &&
          isFullScreen.length==0
        "
      >
        <!-- 画面有默认视频资源时候的模块 -->
        <div v-for="(item, index) in liveList" :key="item.sid + 'four'">
          <div
            class="movie-box-more"
            :style="
              activeChoose === index
                ? 'border: 2px solid #46BAFF;'
                : 'border: 2px solid #13345F;'
            "
            @click="activeChoose = index"
            :key="item.deviceId"
            v-if="isActiveChange(item.sid) == false && item.eqId > 0"
          >
            <div class="movie-header" style="height:30px;line-height:30px">
              <div class="movie-title" style="font-size:15px">{{`${item.pname ? item.pname + ' -': '' } ${item.cameraSn ? item.cameraSn : ''}`}}</div>
              <div
                class="movie-handle-more"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-six-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control-six.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-nine"
                  v-if="frameIs == 16 && isActiveControl(item.sid)"
                >
                  <div class="bth-item" @click="fullScreen(item.sid)" style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px">全屏</div>
                  <div
                    class="bth-item"
                    style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <!-- <div class="bth-item">实时</div> -->
                  <div class="bth-item" @click="closeVideo(item.sid)" style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px">关闭</div>
                </div>
              </div>
            </div>
            <vue-video
              :key="`vbx16_${index}`"
              class="movie2"
              @dblclick.native="pullUp(item.sid, index)"
              :refName="'video' + (index + 1)"
              :ref="'video' + (index + 1)"
              :imgSrc="item.url"
              :liveSrc="item.url"
            ></vue-video>
          </div>
          <!--  -->
          <!-- 画面切换播放源 -->
          <div class="movie-box-more" v-if="isActiveChange(item.sid)">
            <div class="movie-header">
              <div class="movie-title"></div>
              <div
                class="movie-handle-more"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-six-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control-six.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-nine"
                   style="padding:0 4px;"
                  v-if="frameIs == 16 && isActiveControl(item.sid)"
                >
                  <div class="bth-item-disabled" style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px">全屏</div>
                  <div
                    class="bth-item-disabled"
                     style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <div class="bth-item" style="padding:1px 1px;margin-top:8px;font-size:15px;line-height:24px" @click.stop="closeChange(item.sid)">
                     
                    返回
                  </div>
                </div>
              </div>
            </div>
            <div class="camera-box-four" style="width: calc(100% - 10px);height: calc(100% - 80px);">
              <div class="camera-item-four" >
                <div class="camera-header">
                  <span style="font-size:10px">监控编号</span>
                  <span style="font-size:10px">项目名称</span>
                  <span style="font-size:10px">项目地点</span>
                  <span style="font-size:10px">状态</span>
                  <span style="font-size:10px">操作</span>
                </div>
                <div
                  class="camera-body"
                  v-for="(items, count) in cameraList"
                  :key="items.deviceId"
                >
                  <span style="font-size:9px">{{ items.cameraSn }}</span>
                  <span style="font-size:13px">{{items.pname ? items.pname : '—— ——'}}</span>
                  <span style="font-size:13px">{{items.paddress ? items.paddress : '—— ——'}}</span>
                  <span style="font-size:13px" v-if="items.eqStatus === 0">正常</span>
                  <span style="font-size:13px;color:#E6A23C" v-else-if="items.eqStatus === 1">损坏</span>
                  <span style="font-size:13px;color:#909399" v-else-if="items.eqStatus === 2">报废</span>
                  <div>
                    <el-switch
                    v-if="userrole==0"
                      v-model="items.liveStatus"
                      :disabled="((items.liveStatus == 1 && items.eqId != liveList[index].eqId) || items.eqStatus!==0 )"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#46BAFF"
                      inactive-color="#4B5963"
                      @change="
                        liveStatusChange(
                          $event,
                          index,
                          items.eqId,
                          index + 1,
                          count
                        )
                      "
                    >
                    </el-switch>
                     <a v-else  class="astyle" @click.stop="startLive( $event,
                          index,
                          items.eqId,
                          index + 1,
                          count)">播放</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 画面无默认视频资源模块开始 -->
          <div
            class="movie-box-more"
            v-if="isActiveChange(item.sid) == false && item.eqId == null"
          >
            <div class="add-movie-small">
              <img
              style="width:71px;height:71px"
                src="../assets/add-small.png"
                @click="changeVideoSteam(item.sid)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
       <!--  -->
      <!-- 多画面其中一个放大 -->
      <div class="movie-four" v-if="isPullingUp.length != 0 && isFullScreen.length==0">
        <!-- 画面有默认视频资源时候的模块 -->
        <div v-for="(item, index) in pullingData" :key="item.sid + 'fourup'">
          <div
            class="movie-box-four-active"
            :style="
              activeChoose === index
                ? 'border: 2px solid #46BAFF;'
                : 'border: 2px solid #13345F;'
            "
            @click="activeChoose = index"
            :key="item.deviceId"
            v-if="isActiveChange(item.sid) == false && item.eqId > 0"
          >
            <div class="movie-header">
              <div class="movie-title">{{`${item.pname ? item.pname + ' -': '' } ${item.cameraSn ? item.cameraSn : ''}`}}</div>
              <div
                class="movie-handle"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-four"
                  v-if="frameIs !=1 && isActiveControl(item.sid)"
                >
                  <div class="bth-item" @click="fullScreen(item.sid)">全屏</div>
                  <div
                    class="bth-item"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <!-- <div class="bth-item">实时</div> -->
                  <div class="bth-item" @click="closeUpVideo(item.sid)">关闭</div>
                </div>
              </div>
            </div>
            <vue-video
              class="movie2"
              @dblclick.native="pullDown(item.sid, index)"
              :refName="'video' + (index + 1)"
              :ref="'video' + (index + 1)"
              :imgSrc="item.url"
              :liveSrc="item.url"
            ></vue-video>
          </div>
          <!--  -->
          <!-- 画面切换播放源 -->
          <div class="movie-box-four-active" v-if="isActiveChange(item.sid)">
            <div class="movie-header">
              <div class="movie-title"></div>
              <div
                class="movie-handle"
                :style="
                  isActiveControl(item.sid)
                    ? 'background-image: url(' +
                      require('../assets/control-active.png') +
                      ')'
                    : ' background-image: url(' +
                      require('../assets/control.png') +
                      ')'
                "
                @click.stop="controlHeight(item.sid)"
              >
                <div
                  class="bth-box-four"
                  v-if="frameIs == 4 && isActiveControl(item.sid)"
                >
                  <div class="bth-item-disabled">全屏</div>
                  <div
                    class="bth-item-disabled"
                    @click.stop="changeVideoSteam(item.sid)"
                  >
                    切换
                  </div>
                  <div class="bth-item" @click.stop="closeChange(item.sid)">
                    返回
                  </div>
                </div>
              </div>
            </div>
            <div class="camera-box-four" >
              <div class="camera-item-four">
                <div class="camera-header">
                  <span>监控编号</span>
                  <span>项目名称</span>
                  <span>项目地点</span>
                  <span>状态</span>
                  <span>操作</span>
                </div>
                <div
                  class="camera-body"
                  v-for="(items, count) in cameraList"
                  :key="items.deviceId"
                >
                  <span>{{ items.cameraSn }}</span>
                  <span style="font-size:13px">{{items.pname ? items.pname : '—— ——'}}</span>
                  <span style="font-size:13px">{{items.paddress ? items.paddress : '—— ——'}}</span>
                  <span style="font-size:13px" v-if="items.eqStatus === 0">正常</span>
                  <span style="font-size:13px;color:#E6A23C" v-else-if="items.eqStatus === 1">损坏</span>
                  <span style="font-size:13px;color:#909399" v-else-if="items.eqStatus === 2">报废</span>
                  <div>
                    <el-switch
                      v-if="userrole==0"
                      v-model="items.liveStatus"
                      :disabled="((items.liveStatus == 1 && items.eqId != liveList[index].eqId) || items.eqStatus!==0 )"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#46BAFF"
                      inactive-color="#4B5963"
                      @change="
                        liveStatusUpChange(
                          $event,
                          item.eqId,
                          items.eqId,
                          count,
                          item.sid
                        )
                      "
                    >
                    </el-switch>
                    <a v-else class="astyle" @click.stop="startLives($event,
                          item.eqId,
                          items.eqId,
                          count,
                          item.sid)">播放</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 画面无默认视频资源模块开始 -->
          <div
            class="movie-box-four-active"
            @dblclick.stop="pullDown"
            v-if="isActiveChange(item.sid) == false && item.eqId == null"
          >
            <div class="add-movie-small">
              <img
                src="../assets/add-movie.png"
                @click="changeVideoSteam(item.sid)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 画面有默认视频资源模块结束 -->
      <!-- 多画面放大结束 -->

      <div class="movie-control" v-if="isFullScreen.length == 0 ">
        <div class="window-control">
          <span class="control-title">视图控制</span>
          <div
            class="control-num"
            :style="frameIs == 1 ? ' background: #13345f;' : ''"
            @click="frameChange(1)"
          >
            <img src="../assets/frame1.png" alt="" />
          </div>
          <div
            class="control-num"
            :style="frameIs == 4 ? ' background: #13345f;' : ''"
            @click="frameChange(4)"
          >
            <img src="../assets/frame4.png" alt="" />
          </div>
          <div
            class="control-num"
            :style="frameIs == 9 ? ' background: #13345f;' : ''"
           @click="frameChange(9)"
          >
            <img src="../assets/frame9.png" alt="" />
          </div>
          <div
            class="control-num"
            :style="frameIs == 16 ? ' background: #13345f;' : ''"
            @click="frameChange(16)"
          >
            <img src="../assets/frame16.png" alt="" />
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="movie-move" style="overflow: hidden;">
          <span class="control-title">设备操作</span>
          <div class="move-box">
            <img src="../assets/move2.png" class="move-center" alt="" />
            <img
              :src="
                direction == 'left'
                  ? require('../assets/left-active.png')
                  : require('../assets/left.png')
              "
              class="move-left"
              @mousedown="mouseIsUp($event,'left')"
              @mouseup="controlDirection($event, 'left')"
              alt=""
            />
            <img
              :src="
                direction == 'right'
                  ? require('../assets/right-active.png')
                  : require('../assets/right.png')
              "
              class="move-right"
              @mousedown="mouseIsUp($event,'right')"
              @mouseup="controlDirection($event, 'right')"
              alt=""
            />
            <img
              :src="
                direction == 'top'
                  ? require('../assets/top-active.png')
                  : require('../assets/top.png')
              "
              class="move-top"
              @mousedown="mouseIsUp($event,'top')"
              @mouseup="controlDirection($event, 'top')"
              alt=""
            />
            <img
              :src="
                direction == 'bottom'
                  ? require('../assets/bottom-active.png')
                  : require('../assets/bottom.png')
              "
              class="move-bottom"
              @mousedown="mouseIsUp($event,'bottom')"
              @mouseup="controlDirection($event, 'bottom')"
              alt=""
            />
          </div>
          <div class="movie-scale" style="margin-top: 5px">
            <span class="scale-title">缩放</span>
            <div class="scale-down">
              <img src="../assets/scale-down.png" @click="scaleDown" alt="" />
            </div>
            <el-slider
              v-model="defauleScaleValue"
              style="flex: 1"
              :min="0"
              :max="100"
              @change="scaleChange"
            ></el-slider>
            <div class="scale-up">
              <img src="../assets/scale-up.png" alt="" @click="scaleUp" />
            </div>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="movie-move">
          <span class="control-title">事件列表</span>
          <div class="warm-box">
            <div
              class="warm-item"
              v-for="(item, index) in alarmList"
              :key="index + 's'"
              @click="giveAlarmVideo(item)"
            >
              <div class="warm-img" :title="item">
                <video :src="'http://'+item.url" alt="" />"></video>
              </div>
              <div class="warm-txt">
                <p style="font-size: 20px">
                  地点 : <span>{{ item.paddress }}</span>
                </p>
                <p style="color: #ff4690">时间 : <span>画面变化</span></p>
                <p>
                  时间 : <span>{{ item.startDate }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频部分结束 -->
  </div>
</template>
<script>
import {
  geteqlive,
  getLiveList,
  setInsert,
  deleteDevice,
  cameraControl,
  getAlarmMessage,
} from "../api/index";
import vueVideo from "./video.vue";
export default {
  data() {
    return {
      defauleScaleValue: 50, //默认缩放
      defauleScaleValues:50,
      value: "",
      activeChoose: "", //当前选中元素的数组下标
      frameIs: 1, //视图控制
      activeControl: [], //是否允许调出菜单
      direction: "none", //方向控制
      activeChangeList: [], //是否允许摄像头列表
      showWarmVideo: false, //事件视频专用控制参数
      showWarmLiveVideo: false, ////事件实时视频专用控制参数
      alarmLiveVideo: "",
      liveList: [], //当前视窗摄像头列表liveStatusChange
      cameraList: [], //摄像头列表
      isPullingUp: [], //储存双击放大的数组
      isFullScreen: [], //
      pullingData: [], //放大时候的数据
      time: "",
      alarmList: [],
      day: "",
      weekDay: "",
      timer: null,
      mouseDownTime:0,
      mouseUpTime:0,
      liveAll:[],
      userrole:-1,
    };
  },
  components: {
    vueVideo,
  },
  computed:{
    username(){
      return this.$store.getters.username
    },
    
  },
  mounted() {
    if(sessionStorage.getItem("role")==='' || sessionStorage.getItem('role') == undefined){
      this.$router.push('/login')
      return false
    }
    this.userrole=sessionStorage.getItem('role')
    this.initTime();
   if(this.userrole==0){
    this.getAdminLive(); //获取管理员默认视频画面
   }else{
    this.getDefaultLive(); //获取默认视频画面
   }
     
    this.getAlarm(); //获取报警信息
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    activeChoose() {
      this.defauleScaleValue = 50;
    },
  },
  methods: {
    async getAdminLive(){
     let res = await geteqlive({ location: this.frameIs.toString() }); //
      this.$nextTick(() => {
        this.liveList = res.data.data;
      });
    },
    loginout(){
    sessionStorage.clear();
    console.log(sessionStorage.getItem("token"))
    this.$router.push('/login')
    },
    backWarmVideo() {
      this.showWarmVideo = true;
      this.showWarmLiveVideo = false;
    },
    giveAlarmVideo(item) {
      this.showWarmVideo = true;
      this.alarmLiveVideo = 'http://'+item.url;
    },
    alarmIsLive() {
      this.showWarmVideo = false;
      this.showWarmLiveVideo = true;
    },
    async getAlarm() {
      let date = new Date();
      let today = date.getFullYear()+'-'+((date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) :(date.getMonth()+1))+"-"+20 //(date.getDate() <10 ?'0'+ date.getDate() : date.getDate())
      let res = await getAlarmMessage({startTime:today,pageNumber:1,pageSize:20});
      this.alarmList = res.data.data.records;
    },
    initTime() {
      this.timer = setInterval(() => {
        let date = new Date();
        this.time =
          date.getHours() +
          ":" +
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes());
        this.day =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        let weekDay = [
          "星期天",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.weekDay = weekDay[date.getDay()];
      }, 100);
    },
    async scaleChange(e,param) {
      //
      if (this.frameIs == 1 && this.liveList[0].eqId == null) return false; //如果当前是1画面且画面没有播放源
      if (this.activeChoose === "" && this.frameIs != 1) return false; //没有选中任何且不是一画面
      let operation = "";
      if(!this.param){  //确定方向
        let value  = e - this.defauleScaleValues;
        operation = value > 0 ? 8 : 9
      }else{
        operation=param
      }
     this.defauleScaleValues = this.defauleScaleValue
      if (this.frameIs == 1 && this.liveList[0].eqId > 0) {
        let res = await cameraControl({
          ip: this.liveList[0].ip,
            username: this.liveList[0].username,
            password: this.liveList[0].password,
            deviceid:this.liveList[0].deviceid,
            param:operation,
            duration:2000
        });
        console.log(`此次聚焦方式为${operation==8 ? '放大' : '缩小'}`);
      } //当前是1画面切正在播放
      if (this.frameIs != 1 && this.activeChoose !== "") {
        let res = await cameraControl({
            ip: this.liveList[this.activeChoose].ip,
            username: this.liveList[this.activeChoose].username,
            password: this.liveList[this.activeChoose].password,
            deviceid:this.liveList[this.activeChoose].deviceid,
            param:operation,
            duration:2000
        });
         console.log(`此次聚焦方式为${operation==8 ? '放大' : '缩小'}`);
      } //当前不在一画面 且有选中的播放源
    },
    pullUp(num, index) {
      //双击放大
      this.isPullingUp.push(num);
      this.pullingData.push(this.liveList[num - 1]);
      this.activeChoose = index;
    },
    pullDown(num, index) {
      this.isPullingUp = [];
      this.pullingData = [];
    },
    scaleDown() {
      if (this.defauleScaleValue == 0) return false;
      if (this.frameIs == 1 && this.liveList[0].eqId == null) return false; //如果当前是1画面且画面没有播放源
      if (this.activeChoose === "" && this.frameIs != 1) return false; //没有选中任何且不是一画面
      this.defauleScaleValue -= 10;
      this.scaleChange(this.defauleScaleValue,9);
    },
    scaleUp() {
      if (this.defauleScaleValue == 100) return false;
      if (this.frameIs == 1 && this.liveList[0].eqId == null) return false; //如果当前是1画面且画面没有播放源
      if (this.activeChoose === "" && this.frameIs != 1) return false; //没有选中任何且不是一画面
      this.defauleScaleValue += 10;
      this.scaleChange(this.defauleScaleValue,8);
    },
    async mouseIsUp(e,value){
        if (this.frameIs == 1 && this.liveList[0].eqId == null) return false; //如果当前是1画面且画面没有播放源
      if (this.activeChoose === "" && this.frameIs != 1) return false; //没有选中任何
      this.mouseDownTime=0;
      this.mouseUpTime=0;
       this.mouseDownTime = e.timeStamp
      this.direction=value
      console.log('鼠标按下')
    },
    async controlDirection(e, value) {
      //鼠标按下
      console.log('鼠标松开')
      //控制摄像头方向
      if (this.frameIs == 1 && this.liveList[0].eqId == null) return false; //如果当前是1画面且画面没有播放源
      if (this.activeChoose === "" && this.frameIs != 1) return false; //没有选中任何
        this.direction = '';
       this.mouseUpTime = e.timeStamp;
       let time = this.mouseUpTime - this.mouseDownTime;
       time=Math.floor(time)
      //  let count=0;
      //  if(time<1000){ count = 1}else {count = Math.round((time/1000))}
       console.log(time)
      if (this.frameIs == 1 && this.liveList[0].eqId > 0) {
        //当前是1画面切正在播放
        if (e.button == 0) {
          let operation = ""; //方向值
          switch (value) {
            case "left":
              operation = 2;
              break;
            case "right":
              operation = 3;
              break;
            case "top":
              operation = 0;
              break;
            case "bottom":
              operation = 1;
              break;
            default:
              break;
          }
        
            cameraControl({
            ip: this.liveList[0].ip,
            username: this.liveList[0].username,
            password: this.liveList[0].password,
            deviceid:this.liveList[0].deviceid,
            param:operation,
            duration:time

          });
         
             this.mouseUpTime=0;
      this.mouseDownTime=0;
        }
      }
      if (this.frameIs != 1 && this.activeChoose !== "") {
        //当前不在一画面 且有选中的播放源
        if (e.button == 0) {
          let operation = ""; //方向值
          switch (value) {
            case "left":
              operation = 2;
              break;
            case "right":
              operation = 3;
              break;
            case "top":
              operation = 0;
              break;
            case "bottom":
              operation = 1;
              break;
            default:
              break;
          }
             cameraControl({
            ip: this.liveList[this.activeChoose].ip,
            username: this.liveList[this.activeChoose].username,
            password: this.liveList[this.activeChoose].password,
            deviceid:this.liveList[this.activeChoose].deviceid,
            param:operation,
             duration:time
          });
         
            this.mouseUpTime=0;
      this.mouseDownTime=0;
        }
      }
     
    },
     async closeUpVideo(sid) {
      //关闭视频源
      if(this.userrole==0){
        let res = await deleteDevice({ id: sid });
        if (res.data.msg == "success") {
          this.$message.success("关闭成功")
        }else{
           this.$message.error("关闭失败")
        }
        this.liveList=[]
        this.getAdminLive()
      }else{
        this.liveAll.splice(sid-1,1,{cameraSn:null,channelId:null,deviceid:null,eqId:null,eqStatus:null,ip:null,liveStatus:null,paddress:null,password:null,pname:null,sid:sid,token: null,url: null,username:null})
        this.$message.success('关闭成功')
        this.liveList = [];
        this.$nextTick(()=>{
        this.liveList = this.liveAll.slice(0,this.frameIs)
      })
      }
     
      // if (res.data.msg == "success") {
      //   this.liveAll.splice(sid-1,1,{cameraSn:null,channelId:null,deviceid:null,eqId:null,eqStatus:null,ip:null,liveStatus:null,paddress:null,password:null,pname:null,sid:sid,token: null,url: null,username:null})
      //   console.log("删除成功");
      // }
      //  this.liveList = [];
      //  if(this.userrole==0){
      //    this.getAdminLive()
      //  }else{
      //     this.$nextTick(()=>{
      //   this.liveList = this.liveAll.slice(0,this.frameIs)
      // })
      //  }
     
      let timer = setInterval(() => {
        if(this.liveList.length>0){
      this.pullingData=[];
      this.pullingData.push(this.liveList[sid-1]) 
      clearInterval(timer)
        }
      }, 300);
     
     
    },
    async closeVideo(sid) {
      //关闭视频源
      if(this.userrole==0){
        let res = await deleteDevice({ id: sid });
        if (res.data.msg == "success") {
          this.$message.success("关闭成功")
        }else{
          this.$message.error("关闭失败")
        }
        this.liveList = [];
        this.getAdminLive()
      }else{
        this.liveAll.splice(sid-1,1,{cameraSn:null,channelId:null,deviceid:null,eqId:null,eqStatus:null,ip:null,liveStatus:null,paddress:null,password:null,pname:null,sid:sid,token: null,url: null,username:null})
        this.liveList = [];
        this.$nextTick(()=>{
        this.liveList = this.liveAll.slice(0,this.frameIs)
      })
      }
      // let res = await deleteDevice({ id: sid });
      // if (res.data.msg == "success") {
      //    this.liveAll.splice(sid-1,1,{cameraSn:null,channelId:null,deviceid:null,eqId:null,eqStatus:null,ip:null,liveStatus:null,paddress:null,password:null,pname:null,sid:sid,token: null,url: null,username:null})
      //   console.log("删除成功");

      // }
      // this.liveList = [];
      // if(this.userrole==0){
      //    this.getAdminLive()
      //  }else{
      //     this.$nextTick(()=>{
      //   this.liveList = this.liveAll.slice(0,this.frameIs)
      // })
      //  }
    },
    async startLive(e, liveIndex, eqId, closeIndex, cameraIndex){  //开始播放
       // 切换视频源按钮
      if (this.liveList[liveIndex].eqId == null) {
        //满足条件从无到有   添加视频源
        let res = await setInsert({ eqId, id: this.liveList[liveIndex].sid });
        if (res.data.msg == "success") {
          this.liveAll.splice(liveIndex,1,res.data.data) //像liveList添加摄像头
         this.$message.success("添加成功")
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (this.liveList[liveIndex].eqId != eqId && this.liveList[liveIndex].eqId != null ) {
        //本列表已有播放源 且与新播放源不一致 状态是打开   此时关闭现有的 重新赋值新的eqid
        // let res = await deleteDevice({ id: this.liveList[liveIndex].sid }); //先删除旧的
        // if (res.data.msg == "success") {
        //   console.log("删除成功");
        //   }
          let res = await setInsert({ eqId, id: this.liveList[liveIndex].sid }); //添加新的
          if (res.data.msg == "success") {
           this.liveAll.splice(liveIndex,1,res.data.data) //像liveList添加摄像头
            //await this.getCameraList();
          }else{
          this.$message.error(res.data.msg)
        }
        
      }
      this.closeChange(closeIndex);
       this.liveList=[];
     if(this.userrole==0){
       this.getAdminLive()
     }else{
        this.$nextTick(()=>{
       this.liveList=this.liveAll.slice(0,this.frameIs)
     })
     }
    },
    async liveStatusChange(e, liveIndex, eqId, closeIndex, cameraIndex) {
      // 切换视频源按钮
      if (this.liveList[liveIndex].eqId == null && e == true) {
        //满足条件从无到有   添加视频源
        this.cameraList[cameraIndex].liveStatus = e;
        let res = await setInsert({ eqId, id: this.liveList[liveIndex].sid });
        if (res.data.msg == "success") {
          this.liveAll.splice(liveIndex,1,res.data.data) //像liveList添加摄像头
         this.$message.success("添加成功")
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (this.liveList[liveIndex].eqId == eqId && e == false) {
        // 关闭视频源
        this.cameraList[cameraIndex].liveStatus = e;
        let res = await deleteDevice({ id: this.liveList[liveIndex].sid });
        if (res.data.msg == "success") {
          console.log("删除成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (
        this.liveList[liveIndex].eqId != eqId &&
        this.liveList[liveIndex].eqId != null &&
        e == true
      ) {
        //本列表已有播放源 且与新播放源不一致 状态是打开   此时关闭现有的 重新赋值新的eqid
        this.cameraList[cameraIndex].liveStatus = e;
        console.log(this.liveList[liveIndex])
        let res = await deleteDevice({ id: this.liveList[liveIndex].sid }); //先删除旧的
        if (res.data.msg == "success") {
          console.log("删除成功");
          let res = await setInsert({ eqId, id: this.liveList[liveIndex].sid }); //添加新的
          if (res.data.msg == "success") {
           this.liveAll.splice(liveIndex,1,res.data.data) //像liveList添加摄像头
            //await this.getCameraList();
          }else{
          this.$message.error(res.data.msg)
        }
        }else{
          this.$message.error(res.data.msg)
        }
      }
      this.closeChange(closeIndex);
       this.liveList=[];
     if(this.userrole==0){
       this.getAdminLive()
     }else{
       this.$nextTick(()=>{
       this.liveList=this.liveAll.slice(0,this.frameIs)
     })
     }
    },
    async liveStatusUpChange(e, oldEqId, newEqId, cameraIndex, sid) {
      // 放大时候切换视频源按钮
      if (oldEqId == null && e == true) {
        //满足条件从无到有   添加视频源
        this.cameraList[cameraIndex].liveStatus = e;
        let res = await setInsert({ eqId: newEqId, id: sid });
        if (res.data.msg == "success") {
           this.liveAll.splice(sid,1,res.data.data) //像liveList添加摄像头
          console.log("添加成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (oldEqId == newEqId && e == false) {
        // 关闭视频源
        this.cameraList[cameraIndex].liveStatus = e;
        let res = await deleteDevice({ id: sid });
        if (res.data.msg == "success") {
          console.log("删除成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (oldEqId != newEqId && oldEqId != null && e == true) {
        //本列表已有播放源 且与新播放源不一致 状态是打开   此时关闭现有的 重新赋值新的eqid
        this.cameraList[cameraIndex].liveStatus = e;
        let res = await deleteDevice({ id: sid }); //先删除旧的
        if (res.data.msg == "success") {
          console.log("删除成功");
          let res = await setInsert({ eqId: newEqId, id: sid }); //添加新的
          if (res.data.msg == "success") {
            this.liveAll.splice(sid-1,1,res.data.data) //像liveList添加摄像头
            //await this.getCameraList();
          }else{
          this.$message.error(res.data.msg)
        }
        }else{
          this.$message.error(res.data.msg)
        }
      }
      this.liveList = [];
      if(this.userrole==0){
       this.getAdminLive()
      }else{
        this.$nextTick(()=>{
       this.liveList=this.liveAll.slice(0,this.frameIs)
     })
      }
      let timer = setInterval(() => {
        if (this.liveList.length > 0) {
          this.pullingData = [];
          this.pullingData.push(this.liveList[sid - 1]);
          this.closeChange(sid);
          clearInterval(timer);
        }
      }, 200);
    },
     async startLives(e, oldEqId, newEqId, cameraIndex, sid) {
      // 放大时候切换视频源按钮
      if (oldEqId == null ) {
        //满足条件从无到有   添加视频源
        let res = await setInsert({ eqId: newEqId, id: sid });
        if (res.data.msg == "success") {
           this.liveAll.splice(sid,1,res.data.data) //像liveList添加摄像头
        }else{
          this.$message.error(res.data.msg)
        }
      }
      if (oldEqId != newEqId && oldEqId != null ) {
        //本列表已有播放源 且与新播放源不一致 状态是打开   此时关闭现有的 重新赋值新的eqid
        // let res = await deleteDevice({ id: sid }); //先删除旧的
        // if (res.data.msg == "success") {}
          let res = await setInsert({ eqId: newEqId, id: sid }); //添加新的
          if (res.data.msg == "success") {
            this.liveAll.splice(sid-1,1,res.data.data) //像liveList添加摄像头
            //await this.getCameraList();
          }else{
          this.$message.error(res.data.msg)
        }
        
      }
      this.liveList = [];
     if(this.userrole==0){
       this.getAdminLive()
     }else{
        this.$nextTick(()=>{
       this.liveList=this.liveAll.slice(0,this.frameIs)
     })
     }
      let timer = setInterval(() => {
        if (this.liveList.length > 0) {
          this.pullingData = [];
          this.pullingData.push(this.liveList[sid - 1]);
          this.closeChange(sid);
          clearInterval(timer);
        }
      }, 200);
    },
    frameChange(num) {
      if(num==this.frameIs) return false
      // 改变视窗状态  1 4 9 16
      this.showWarmVideo = false;// 关闭事件视频
      this.showWarmLiveVideo = false;//关闭事件实时视频
      this.activeChoose = "";//取消所有选中
      this.pullingData=[];//置空放大数据
      this.isPullingUp=[];//取消所有放大
      this.activeControl=[];//取消所有列表高亮
      this.activeChangeList=[];//取消所有摄像头列表状态
      this.defauleScaleValue = 50;
      this.frameIs = num;
      this.liveList = [];
      if(this.userrole==0){
        this.getAdminLive()
      }else{
        this.$nextTick(() => {
        this.liveList=this.liveAll.slice(0,num)
      });
      }
    },
    async getDefaultLive() {
      //获取默认视频画面
      for (let index = 0; index < 16; index++) {
        this.liveAll.push({cameraSn:null,channelId:null,deviceid:null,eqId:null,eqStatus:null,ip:null,liveStatus:null,paddress:null,password:null,pname:null,sid:index+1,token: null,url: null,username:null})
      }
      this.liveList = this.liveAll.slice(0,this.frameIs)
    },
    async getCameraList() {
      //获取摄像头列表
      let res = await getLiveList();
      this.cameraList = res.data.data;
    },
    isActiveControl(num) {
      //监测控制按钮高亮
      return this.activeControl.includes(num);
    },
    isActiveChange(num) {
      //检测跳转摄像头列表
      return this.activeChangeList.includes(num);
    },
    fullScreen(num,full){ //全屏控制
     if(this.isFullScreen.length>0){
       this.isFullScreen=[]
     }else{
        if(full){
        this.fullMovieDate ={url:full,type:num}
         this.isFullScreen.push(num);
        }else{
          this.fullMovieDate = JSON.parse(JSON.stringify(this.liveList[num-1]))
         this.isFullScreen.push(num);
        }
      
     }
    },
    closeFullScreen(status){
      this.$nextTick(()=>{
          this.isFullScreen=[];
      this.fullMovieDate=''
      })
       
    },
    controlHeight(num) {
      //高亮控制
      if (this.isActiveControl(num)) {
        for (let index = 0; index < this.activeControl.length; index++) {
          this.activeControl = this.activeControl.filter((item) => {
            return item != num;
          });
        }
      } else {
        this.activeControl.push(num);
      }
    },
    changeVideoSteam(num) {
      this.activeChangeList.push(num);
      this.getCameraList();
    },
    closeChange(num) {
      if (this.isActiveControl(num)) {
        for (let index = 0; index < this.activeControl.length; index++) {
          this.activeControl = this.activeControl.filter((item) => {
            return item != num;
          });
        }
      }
      if (this.isActiveChange(num)) {
        for (let index = 0; index < this.activeChangeList.length; index++) {
          this.activeChangeList = this.activeChangeList.filter((item) => {
            return item != num;
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.astyle{
  color:#46baff;
 font-size: 14px;
 line-height: 14px;
 text-decoration: underline;
 cursor: pointer;
}
.box {
  width: 1920px;
  height: 1080px;
  background: linear-gradient(90deg, #0b152d, #122247, #0b152d);
}
header {
  width: 100%;
  height: 110px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #13345f;
}
.left-flex {
  display: flex;
  flex: 1;
}
.logoBox {
  width: 104px;
  height: 42px;
  overflow: hidden;
}
.logoBox img {
  width: 100%;
  height: 100%;
}
.title {
  flex: 1;
  text-align: center;
}
.title-cn {
  font-size: 40px;
  font-family: " Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
  margin: 0;
}
.title-en {
  font-size: 22px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #275988;
}
.status {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-size: 22px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
.username {
  padding-right: 29px;
  border-right: 1px solid #46baff;
}
.loginout {
  padding-left: 30px;
  cursor: pointer;
}
.time {
  font-size: 30px;
  font-family: "Source Han Sans CN";
  font-weight: 400;
  color: #46baff;
  padding-right: 10px;
  border-right: 2px solid #46baff;
}
.date {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.day {
  margin-left: 10px;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #46baff;
  display: flex;
  flex-direction: column;
}
.movie-part {
  display: flex;
  padding: 20px;
  width: 100%;
  height: calc(1080px - 110px);
  box-sizing: border-box;
  justify-content: space-between;
}
.movie-four {
  display: flex;
  padding: 20px;
  width: 75%;
  flex-wrap: wrap;
  justify-content: space-between;
  background-image: url("../assets/movie-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.movie-box {
  padding: 20px;
  width: 75%;
  background-image: url("../assets/movie-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.movie-box-full{
  padding:0 25px;
  width: 100%;
  background-image: url("../assets/movie-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.close{
  width: 23px;
  height: 23px;
  position: absolute;
  top: 35px;
  right: 50px;
  cursor: pointer;
}
.movie-box-four {
  width: 670px;
  height: 432px;
  box-sizing: border-box;
  border: 2px solid #13345f;
  transition: width 0.3s linear;
}
.movie-box-nine{
  width: 450px;
  height: 290px;
  box-sizing: border-box;
  border: 2px solid #13345f;
  transition: width 0.3s linear;
}
.movie-box-more{
  width: 335px;
  height: 216px;
  box-sizing: border-box;
  border: 2px solid #13345f;
  transition: width 0.3s linear;
}
.movie-box-four-active {
  width: 1410px;
  height: 890px;
  box-sizing: border-box;
  border: 2px solid #13345f;
  transition: width 0.3s linear;
}
.movie-title {
  font-size: 30px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
.movie-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
  height: 60px;
}
.movie-handle {
  width: 37px;
  height: 30px;
  cursor: pointer;
  background-image: url("../assets/control.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.movie-handle-six {
  width: 25px;
  height: 20px;
  cursor: pointer;
  background-image: url("../assets/control.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.movie-handle-more {
  width: 19px;
  height: 15px;
  cursor: pointer;
  background-image: url("../assets/control.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.movie-handle img {
  width: 100%;
}
.movie1 {
  width: 100%;
  margin-top: 10px !important;
  height: calc(100% - 60px);
}
.movie-full{
  width: 88%;
  height: calc(100% - 60px);  
}
.movie2 {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
.add-movie {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-movie-small {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-movie-small img{
  cursor: pointer;
  user-select: none;
}
.movie-control {
  width: 21.9%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.window-control {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("../assets/window-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px 20px;
}
.control-num {
  width: 60px;
  height: 58px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.control-num img {
  width: 100%;
}
.control-title {
  font-size: 30px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
.movie-move {
  background-image: url("../assets/control-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px 20px;
  flex: 1;
  overflow-y: scroll;
}
.move-box {
  width: 244px;
  height: 244px;
  margin: 10px auto;
  overflow: hidden;
  border-radius: 50%;
  background-image: url("../assets/move1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.move-left {
  position: absolute;
  left: 5px;
  top: 40.5px;
  z-index: 10;
}
.move-right {
  position: absolute;
  right: 5px;
  top: 42px;
  z-index: 10;
}
.move-top {
  position: absolute;
  top: 4.5px;
  z-index: 10;
}
.move-bottom {
  position: absolute;
  bottom: 5px;
  left: 41px;
  z-index: 10;
}
.movie-scale {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  z-index: 10;
}
.scale-title {
  font-size: 20px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
.scale-up,
.scale-down {
  margin: 0 20px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.move-center {
  position: relative;
  z-index: 1;
}
.warm-box {
  display: flex;
  flex-direction: column;
}
.warm-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #13345f;
}
.warm-img {
  width: 128px;
  height: 72px;
}
.warm-img video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.warm-txt p {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #46baff;
}
.bth-box {
  position: absolute;
  top: 50px;
  left: -10px;
  width: 70px;
  color: #46baff;
  font-size: 20px;
  z-index: 999;
}
.bth-box-four {
  position: absolute;
  top: 40px;
  left: -30px;
  width: 70px;
  color: #46baff;
  font-size: 20px;
  z-index: 999;
}
.bth-box-nine {
  position: absolute;
  top: 20px;
  left: -32px;
  width: 60px;
  color: #46baff;
  font-size: 20px;
  z-index: 999;
}
.bth-item {
  margin-top: 10px;
  padding: 4px 8px;
  border: 2px solid #46baff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background: #0E162D;;
}
.bth-item-disabled {
  margin-top: 10px;
  padding: 4px 8px;
  color: #839cad;
  background-color: rgba(75, 89, 99, 0.87);
  border: 2px solid #839cad;
  border-radius: 5px;
  text-align: center;
  cursor: not-allowed;
}
.camera-box {
  margin: 0 40px;
  width: calc(100% - 80px);
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.camera-box-four {
  width: calc(100% - 80px);
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  max-height: 700px;
  overflow-y: scroll;
}
.camera-item {
  width: 90%;
}
.camera-item-four {
  width: 96%;
  margin-left: 10px;
}
.camera-header {
  display: flex;
  border: 2px solid #13345f;
  border-bottom: none;
  height: 32px;
  line-height: 32px;
  background: #13345f;
}
.camera-header span {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
.camera-body {
  display: flex;
  border: 2px solid #13345f;
  border-top: none;
    justify-content: center;
    align-items: center;
}
.camera-body:nth-child(1) {
  border-top: none;
}
.camera-body span,
.camera-body div {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #46baff;
}
/* 公共样式滚动条 */
::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  background-image: linear-gradient(#46baff, #13345f);
  height: 80px;
  border-radius: 3px;
}
</style>