<template>
  <div id="app">
    <router-view>
      
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    if(sessionStorage.getItem('username')){
      this.$store.commit("setUserName",sessionStorage.getItem('username'))
    }
    if(sessionStorage.getItem('role')){
      this.$store.commit("setUserRole",sessionStorage.getItem('role'))
    }
  },
}
</script>

<style>
*{
   margin: 0;
  padding: 0;
}
body{
  margin: 0;
  padding: 0;
}
.el-slider__bar{
  background: linear-gradient(90deg, #183563, #2F5CA2)
}
.el-slider__button-wrapper{
border-radius: 50%;
border: 1px dashed #2F5CA2;
}
.el-slider__button{
 width: 18px!important;
 height: 18px!important;
 border: 0!important;
 background-color: #2F5CA2!important;
}
</style>
