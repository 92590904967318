<template>
  <video-player
    class="video-player vjs-custom-skin"
    style="margin:0 auto;"
    @click.stop="''"
    :key="key"
    :playsinline="false"
    :ref="refName"
    :options="playerOptions"
  ></video-player>
</template>
<script>
export default {
  props: {
    refName: {
      type: String,
      value: "none"
    },
    imgSrc: {
      type: String
    },
    liveSrc: {
      type: String
    },
    key:{
      type: String
    },
    aspectRatio:{
      type:String,
    },
    playType:{
      type:String,
      value:"rtmp/mp4"
    }
  },
  methods: {
  },
  watch: {
    imgSrc () {
      console.log(this.imgSrc)
    },
  },
  mounted() {
    this.$refs[`${this.refName}`].initialize()
     this.$once('hook:beforeDestroy', () => {
      this.$refs[`${this.refName}`].dispose();
    })
  },
  //  destroyed() {
  //  this.$refs[`${this.refName}`].dispose()
  // },
  data () {
    return {
      // 视频播放
      playerOptions: {
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "false", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        fluid: true, 
        aspectRatio: this.aspectRatio ? this.aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        techOrder: ["html5",], // 兼容顺序 flash
        flash: {
          hls: { withCredentials: false },
        },
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            //流配置，数组形式，会根据兼容顺序自动切换
             type: 'application/x-mpegURL',
             src: this.liveSrc ? this.liveSrc :''
           // type: "rtmp/mp4",
          // src:'rtmp://mobliestream.c3tv.com:554/live/goodtv.sdp',
          },
        ],
        poster: this.imgSrc, //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "暂无视频源或播放错误", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      
    }
  },
};
</script>
<style scoped>
/* >>>.vjs-control-bar{
    display: none!important;
} */
>>>.vjs-tech{
     pointer-events: none!important;
}
>>>.vjs-live-control{
    display: none!important;
}
>>>  .vjs-play-control.vjs-playing .vjs-icon-placeholder:before{
  content:''!important
}
>>>.vjs-control-bar{
 display: flex;
 justify-content: flex-end;
}
>>>.vjs-custom-skin > .video-js .vjs-control{
float: right !important;
}
>>>.vjs-progress-control{
  display: none!important;
}
</style>