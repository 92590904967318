import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import 'video.js/dist/video-js.css'
import VideoPlayer from 'vue-video-player'
import 'videojs-flash'
import hls from 'videojs-contrib-hls'
import "vue-video-player/src/custom-theme.css"
// Vue.use(hls)
Vue.use(VideoPlayer)
Vue.use(ElementUI);
// import 'amfe-flexible'
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
