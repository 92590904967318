import axios from 'axios'
import store from '../store/index'
// 创建 axios 实例
let http = axios.create({
   //baseURL: "http://192.168.31.131:8081/",//process.env.NODE_ENV === 'production' ? 'http://47.100.254.90/cpic-bim' : "http://47.100.254.90/cpic-bim", "https://www.bixiaoyi.cn:9081/apiTest"
  headers: {'Content-Type': 'application/json'},
  timeout: 50000
})

// 设置 post、put 默认 Content-Type
//http.defaults.headers.post['Content-Type'] = 'application/json'
//http.defaults.headers.put['Content-Type'] = 'application/json'

// 添加请求拦截器
http.interceptors.request.use(config => {

  if (sessionStorage.getItem('token')) {
     
     config.headers['token'] = sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
 }
  // if (config.method === 'post' || config.method === 'put') {
  //   // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
  //   // config.data = JSON.stringify(config.data)
  // } else if (config.method === 'get' && browser.isIE) {
  //   // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
  //   // let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
  //   // config.url += symbol + '_=' + Date.now()
  // }
  // 请求发送前进行处理
  return config
}, error => {
  // 请求错误处理
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
  return response
}, error => {
  
  return Promise.reject(error)
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default  http
 